<a *ngIf="item" routerLink="{{item.url}}" [queryParams]="item.params" (click)="onOpen()">
  <div class="app-card-content-wrapper">
    <!-- <div class="app-card-mark {{item.doctype}}"></div> -->
    <div class="app-card-media">
      <div class="app-card-thumb" [style.background-image]="thumb">
        <div matTooltipPosition="above" [matTooltip]="lock.tooltip" class="app-lock {{ lock.class }}" *ngIf="legacyLocks && lock && lock.access != 'open'">
          <mat-icon>{{ lock.icon }}</mat-icon>s
        </div>
      </div>
    </div>

    <div class="app-card-content">
      <h3 *ngIf="item.title" class="app-card-title">
        {{ getTitle() }}
      </h3>
      <div *ngIf="item.authors && item.authors.length > 0" class="app-card-authors">
        <!-- <mat-icon>person</mat-icon> -->
        {{item.authors[0]}}
      </div>
      <div *ngIf="item.date" class="app-card-authors">
        <!-- <mat-icon>today</mat-icon> -->
        {{item.date}}
      </div>

      <div *ngIf="item.localDescriptions || item.description" class="app-card-description">
        {{ getDescription() }}
      </div>

      <div *ngIf="item.hits" class="app-card-hits">
        <strong>{{item.hits}}</strong> 
        <span>{{ 'common.n_occurrences' | appPlural : item.hits }}</span>
      </div>

      <!-- <div class="app-card-sources" *ngIf="item.sources">
        <img *ngFor="let source of item.sources" class="app-card-source" src="{{ sourceLogo(source) }}" >
      </div> -->

      <div class="app-card-bottom">
      <div class="app-card-access" [class]="lock.class" *ngIf="lock && !legacyLocks"> 
        <mat-icon>{{ lock.icon }}</mat-icon>
         {{ 'search.access.' + lock.access | translate}}
      </div>


      <img *ngIf="item.library" class="app-card-logo" src="{{ libraryLogo() }}" >
      <div class="app-card-doctypes">        
        <div *ngIf="item.doctype" class="app-card-doctype app-doctype-{{item.doctype}}">
          {{ 'model.' + item.doctype | translate }}
        </div>
        <div *ngIf="item.category" class="app-card-doctype app-doctype-category">
          {{ 'category.' + item.category | translate }}
        </div>
      </div>
    </div>

    </div>
  </div>

  <div *ngIf="selectable" class="app-selectable" [class.app-selected]="item.selected"
    (click)="item.selected = !item.selected; $event.preventDefault();$event.stopPropagation()">
    <div class="app-check"></div>
  </div>

  <div class="app-card-like" *ngIf="!selectable">
    <mat-icon *ngIf="(in == 'search' || in == 'folder-follower') && settings.folders && auth.isLoggedIn()" [matMenuTriggerFor]="favoritesMenu" (click)="$event.preventDefault();$event.stopPropagation();checkFolders()" matTooltip="{{ 'folders.like' | translate }}">favorite</mat-icon>
    <mat-icon *ngIf="in == 'folder-owner' && settings.folders && auth.isLoggedIn()" (click)="onDislike(folder); $event.preventDefault();$event.stopPropagation()" matTooltip="{{ 'folders.clear' | translate }}">clear</mat-icon>
    <mat-icon (click)="getMetadata(item.uuid); $event.preventDefault();$event.stopPropagation()" matTooltip="{{ 'folders.info' | translate }}">info</mat-icon>
  </div>

</a>

<mat-menu #favoritesMenu="matMenu" xPosition="before" class="app-dropdown-menu">
  <ng-template matMenuContent>
    <div mat-menu-item tabindex="0" class="app-dropdown-item" *ngFor="let folder of folderService.getMyFolders()" (click)="onLike(folder)">
      {{ folder.name }}
    </div>
    <!-- <div mat-menu-item tabindex="0" class="app-dropdown-item app-dropdown-new" (click)="onNewFolder()">+ Nový seznam</div> -->
    <div class="app-dropdown-input">
        <input #matInput [placeholder]="'folders.new_list' | translate" (click)="$event.stopPropagation()" (keyup.enter)="onNewFolder(matInput.value)">
    </div>
  </ng-template>
</mat-menu>