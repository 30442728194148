<app-search-count></app-search-count>

<div class="app-filters-wrapper">
  <app-search-filters-used *ngIf="searchService.query.anyFilter()"></app-search-filters-used>

  <mat-accordion [displayMode]="'flat'" [multi]="true" class="app-filters-wrapper">

    
    <mat-expansion-panel *ngIf="filters.indexOf('accessibility') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.accessibility.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="app-filter-items">
        <mat-radio-group [(ngModel)]="searchService.query.accessibility">
          <ul>
            <li *ngFor="let item of searchService.accessibility" [class.app-selected]="item.value === searchService.query.accessibility">
              <mat-radio-button [disableRipple]="true" [value]="item.value" (change)="searchService.setAccessibility(item.value);analytics.sendEvent('search', 'accessibility', item.value)">
                {{ 'search.accessibility.' + item.value | translate}}
              </mat-radio-button>
              <span class="app-filter-count">{{item.count}}</span>
            </li>
          </ul>
        </mat-radio-group>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="filters.indexOf('access') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.access.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>


      <div class="app-filter-items">
        <mat-radio-group [(ngModel)]="searchService.query.access">
          <ul>
            <ng-container *ngFor="let item of searchService.accessArray()">
              <li *ngIf="searchService.showAccessFilter(item.value)" [class.app-selected]="item.value === searchService.query.access">
                <mat-radio-button [value]="item.value" (change)="searchService.setAccess(item.value);analytics.sendEvent('search', 'access', item.value)">
                  {{ 'search.access.' + item.value | translate}}
                </mat-radio-button>
                <div *ngIf="item.value != 'all' && item.value != 'accessible'" class="app-lock {{ item.accessible ? 'app-lock-licence-open' : 'app-lock-licence-locked' }}">
                  <mat-icon>{{ licences.accessIcon(item.value, item.accessible) }}</mat-icon>
                </div>
                <span class="app-filter-count">{{item.count}}</span>
              </li>
            </ng-container>
          </ul>
        </mat-radio-group>
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="searchService.licences.length && filters.indexOf('licences') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.licences' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.licences; let i = index">
          <li *ngIf="i < 6 || categoriesExpanded" [title]="licences.label(item.value)" [class.app-selected]="searchService.query.licences.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.licences.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.licences, item.value);analytics.sendEvent('search', 'licence', item.value)">
              {{ licences.label(item.value) }}
            </mat-checkbox>
            <div class="app-lock {{ licences.buildLock([item.value], false).class }}" *ngIf="licences.buildLock([item.value], false)">
              <mat-icon>{{ licences.buildLock([item.value], false).icon }}</mat-icon>
            </div>
            <mat-icon class="app-info" tabindex="0" (click)="showLicenceDialog(item.value); $event.preventDefault();$event.stopPropagation()">info</mat-icon>
            <span class="app-filter-count">
              {{item.count}}
            </span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.licences.length > 6"
        tabindex="0"
        role="button"
        (click)="categoriesExpanded = !categoriesExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (categoriesExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>



    <mat-expansion-panel *ngIf="searchService.doctypes.length && filters.indexOf('doctypes') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.doctypes' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
        <ul>
          <li *ngFor="let item of searchService.doctypes" [class.app-selected]="searchService.query.doctypes.indexOf(item.value) >= 0">
            <mat-checkbox
                [checked]="searchService.query.doctypes.indexOf(item.value) >= 0"
                (change)="searchService.toggleFilter(searchService.query.doctypes, item.value);analytics.sendEvent('search', 'doctype', item.value)">
                {{'model_plural.' + item.value | translate | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ul>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="searchService.categories.length && filters.indexOf('categories') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.categories' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.categories; let i = index">
          <li *ngIf="i < 6 || categoriesExpanded" [title]="'category.' + item.value | translate | appUpcase" [class.app-selected]="searchService.query.categories.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.categories.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.categories, item.value);analytics.sendEvent('search', 'category', item.value)">
              {{'category.' + item.value | translate | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.categories.length > 6"
        tabindex="0"
        role="button"
        (click)="categoriesExpanded = !categoriesExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (categoriesExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="searchService.sources.length && filters.indexOf('sources') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.sources' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.sources; let i = index">
          <li *ngIf="i < 6 || sourcesExpanded" [title]="'source.' + item.value | translate | appRemovePrefix:'source.'" [class.app-selected]="searchService.query.sources.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.sources.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.sources, item.value);analytics.sendEvent('search', 'cource', item.value)">
              {{'source.' + item.value | translate | appRemovePrefix:'source.' }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.sources.length > 6"
        tabindex="0"
        role="button"
        (click)="sourcesExpanded = !sourcesExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (sourcesExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.year-range.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="app-filter-items">
        <div class="year-range-wrapper">
          <fieldset>
            <legend class="app-visualy-hidden">{{ 'search.year-range.label' | translate }}</legend>
            <div class="year-range">
              <label for="yearFrom">{{ 'search.year-range.from' | translate }}</label>
              <input id="yearFrom" [(ngModel)]="yearFrom" (change)="onYearFromValueChanged()" type="number">
              <label for="yearTo">{{ 'search.year-range.to' | translate }}</label>
              <input id="yearTo" [(ngModel)]="yearTo" (change)="onYearToValueChanged()" type="number">
            </div>
          </fieldset>
          <button (click)="applyYearRange()" mat-flat-button color="primary">{{ 'search.year-range.apply' | translate }}</button>
        </div>
      </div>
    </mat-expansion-panel>




    <mat-expansion-panel *ngIf="searchService.keywords.length && filters.indexOf('keywords') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.keywords' | translate }}            
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.keywords; let i = index">
          <li *ngIf="i < 6 || keywordsExpanded" [title]="item.value | appUpcase" [class.app-selected]="searchService.query.keywords.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.keywords.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.keywords, item.value);analytics.sendEvent('search', 'keyword', item.value)">
              {{ item.value | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.keywords.length > 6"
        tabindex="0"
        role="button"
        (click)="keywordsExpanded = !keywordsExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (keywordsExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="searchService.authors.length && filters.indexOf('authors') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.authors' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.authors; let i = index ">
          <li *ngIf="i < 6 || authorsExpanded" [title]="item.value | appUpcase" [class.app-selected]="searchService.query.authors.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.authors.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.authors, item.value);analytics.sendEvent('search', 'author', item.value)">
              {{ item.value | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.authors.length > 6"
        tabindex="0"
        role="button"
        (click)="authorsExpanded = !authorsExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (authorsExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>



    <mat-expansion-panel *ngIf="searchService.geonames.length && filters.indexOf('geonames') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.geonames' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.geonames; let i = index">
          <li *ngIf="i < 6 || geonamesExpanded" [title]="item.value | appUpcase" [class.app-selected]="searchService.query.geonames.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.geonames.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.geonames, item.value);analytics.sendEvent('search', 'geoname', item.value)">
              {{ item.value | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.geonames.length > 6"
        tabindex="0"
        role="button"
        (click)="geonamesExpanded = !geonamesExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (geonamesExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="searchService.genres.length && filters.indexOf('genres') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.genres' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.genres; let i = index">
          <li *ngIf="i < 6 || genresExpanded" [title]="item.value | appUpcase" [class.app-selected]="searchService.query.genres.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.genres.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.genres, item.value);analytics.sendEvent('search', 'genre', item.value)">
              {{ item.value | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.genres.length > 6"
        tabindex="0"
        role="button"
        (click)="genresExpanded = !genresExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (genresExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="searchService.publishers.length && filters.indexOf('publishers') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.publishers' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.publishers; let i = index">
          <li *ngIf="i < 6 || publishersExpanded" [title]="item.value | appUpcase" [class.app-selected]="searchService.query.publishers.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.publishers.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.publishers, item.value);analytics.sendEvent('search', 'publisher', item.value)">
              {{ item.value | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.publishers.length > 6"
        tabindex="0"
        role="button"
        (click)="publishersExpanded = !publishersExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (publishersExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="searchService.places.length && filters.indexOf('places') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.places' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.places; let i = index">
          <li *ngIf="i < 6 || placesExpanded" [title]="item.value | appUpcase" [class.app-selected]="searchService.query.places.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.places.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.places, item.value);analytics.sendEvent('search', 'place', item.value)">
              {{ item.value | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.places.length > 6"
        tabindex="0"
        role="button"
        (click)="placesExpanded = !placesExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (placesExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>



    <mat-expansion-panel *ngIf="searchService.locations.length && filters.indexOf('locations') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.locations' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.locations; let i = index">
          <li *ngIf="i < 6 || locationsExpanded" [title]="'sigla.' + item.value | translate | appRemovePrefix:'sigla.'" [class.app-selected]="searchService.query.locations.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.locations.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.locations, item.value);analytics.sendEvent('search', 'location', item.value)">
              {{'sigla.' + item.value | translate | appRemovePrefix:'sigla.' }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.locations.length > 6"
        tabindex="0"
        role="button"
        (click)="locationsExpanded = !locationsExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (locationsExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="searchService.languages.length && filters.indexOf('languages') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.languages' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.languages; let i = index">
          <li *ngIf="i < 6 || languagesExpanded" [title]="'language.' + item.value | translate | appUpcase" [class.app-selected]="searchService.query.languages.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.languages.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.languages, item.value);analytics.sendEvent('search', 'language', item.value)">
              {{'language.' + item.value | translate | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.languages.length > 6"
        tabindex="0"
        role="button"
        (click)="languagesExpanded = !languagesExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (languagesExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel *ngIf="searchService.collections.length && filters.indexOf('collections') > -1" class="app-filter-panel" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'search.collections' | translate }}   
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngFor="let item of searchService.collections; let i = index">
          <li *ngIf="i < 6 || collectionsExpanded" [title]="collectionService.getNameByPid(item.value) | appUpcase" [class.app-selected]="searchService.query.collections.indexOf(item.value) >= 0">
            <mat-checkbox 
              [checked]="searchService.query.collections.indexOf(item.value) >= 0"
              (change)="searchService.toggleFilter(searchService.query.collections, item.value);analytics.sendEvent('search', 'collection', item.value)">
              {{ collectionService.getNameByPid(item.value) | appUpcase }}
            </mat-checkbox>
            <span class="app-filter-count">{{item.count}}</span>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="searchService.collections.length > 6"
        tabindex="0"
        role="button"
        (click)="collectionsExpanded = !collectionsExpanded"
        class="app-expand">
        {{ 'filters.btn_show_' + (collectionsExpanded ? 'less' : 'more') | translate }}  
      </div>
    </mat-expansion-panel>


  </mat-accordion>



  <mat-expansion-panel *ngIf="ai.similaritySearchAvailable()" class="app-filter-panel" [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Tester
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li>
        <mat-checkbox
          [checked]="ai.isSimilaritySearchEnabled()"
          (change)="toggleSimilarySearchEnabled()">
          Sémantické vyhledávání
        </mat-checkbox>
      </li>
    </ul>
  </mat-expansion-panel>

</div>