<div class="app-similarity-dialog app-dialog">
  
<mat-grid-list cols="4" rowHeight="300px" gutterSize="8px">
  <mat-grid-tile *ngFor="let item of items" [style.background]="'#f5f5f5'">
    <a class="app-grid-item" [href]="item.metadata.url" target="_blank">
      <img [src]="getIIIFRegionUrl(item.metadata)"  class="app-image" />
      <div class="app-details">
        <h3>{{ getTitle(item.metadata) }}</h3>
        <p>{{ item.metadata.date  }}</p>
      </div>
    </a>
  </mat-grid-tile>
</mat-grid-list>

</div>